export const SUBSCRIBE_TO_BLUEPRINT  = 'SUBSCRIBE_TO_BLUEPRINT';
export const SUBSCRIBED_TO_BLUEPRINT = 'SUBSCRIBED_TO_BLUEPRINT';
export const RECEIVED_BLUEPRINT      = 'RECEIVED_BLUEPRINT';

export const SUBSCRIBE_TO_SUMMARIES      = 'SUBSCRIBE_TO_SUMMARIES';
export const SUBSCRIBED_TO_SUMMARIES     = 'SUBSCRIBED_TO_SUMMARIES';
export const RECEIVED_SUMMARIES          = 'RECEIVED_SUMMARIES';
export const SUMMARIES_READY             = 'SUMMARIES_READY';
export const SUMMARIES_LAST_PAGE         = 'SUMMARIES_LAST_PAGE';
export const GO_TO_PREVIOUS_SUMMARIES    = 'GO_TO_PREVIOUS_SUMMARIES';
export const GO_TO_NEXT_SUMMARIES        = 'GO_TO_NEXT_SUMMARIES';
export const GO_TO_FIRST_SUMMARIES       = 'GO_TO_FIRST_SUMMARIES';
export const RECIEVED_PREVIOUS_SUMMARIES = 'RECIEVED_PREVIOUS_SUMMARIES';
export const RECIEVED_NEXT_SUMMARIES     = 'RECIEVED_NEXT_SUMMARIES';
export const RECIEVED_FIRST_SUMMARIES    = 'RECIEVED_FIRST_SUMMARIES';

export const SUBSCRIBE_TO_ALL_FAVORITES      = 'SUBSCRIBE_TO_ALL_FAVORITES';
export const SUBSCRIBED_TO_ALL_FAVORITES     = 'SUBSCRIBED_TO_ALL_FAVORITES';
export const RECEIVED_ALL_FAVORITES          = 'RECEIVED_ALL_FAVORITES';
export const ALL_FAVORITES_READY             = 'ALL_FAVORITES_READY';
export const ALL_FAVORITES_LAST_PAGE         = 'ALL_FAVORITES_LAST_PAGE';
export const GO_TO_PREVIOUS_ALL_FAVORITES    = 'GO_TO_PREVIOUS_ALL_FAVORITES';
export const GO_TO_NEXT_ALL_FAVORITES        = 'GO_TO_NEXT_ALL_FAVORITES';
export const GO_TO_FIRST_ALL_FAVORITES       = 'GO_TO_FIRST_ALL_FAVORITES';
export const RECIEVED_PREVIOUS_ALL_FAVORITES = 'RECIEVED_PREVIOUS_ALL_FAVORITES';
export const RECIEVED_NEXT_ALL_FAVORITES     = 'RECIEVED_NEXT_ALL_FAVORITES';
export const RECIEVED_FIRST_ALL_FAVORITES    = 'RECIEVED_FIRST_ALL_FAVORITES';

export const SUBSCRIBE_TO_TAGS  = 'SUBSCRIBE_TO_TAGS';
export const SUBSCRIBED_TO_TAGS = 'SUBSCRIBED_TO_TAGS';
export const RECEIVED_TAGS      = 'RECEIVED_TAGS';

export const SUBSCRIBE_TO_TAG  = 'SUBSCRIBE_TO_TAG';
export const SUBSCRIBED_TO_TAG = 'SUBSCRIBED_TO_TAG';
export const RECEIVED_TAG      = 'RECEIVED_TAG';

// TODO: Where is this used?
export const SUBSCRIBE_TO_USER                       = 'SUBSCRIBE_TO_USER';
export const SUBSCRIBE_TO_USER_DISPLAY_NAME          = 'SUBSCRIBE_TO_USER_DISPLAY_NAME';
export const SUBSCRIBED_TO_USER_DISPLAY_NAME         = 'SUBSCRIBED_TO_USER_DISPLAY_NAME';
export const SUBSCRIBE_TO_USER_BLUEPRINTS_SUMMARIES  = 'SUBSCRIBE_TO_USER_BLUEPRINTS_SUMMARIES';
export const SUBSCRIBED_TO_USER_BLUEPRINTS_SUMMARIES = 'SUBSCRIBED_TO_USER_BLUEPRINTS_SUMMARIES';
export const RECEIVED_USER_DISPLAY_NAME              = 'RECEIVED_USER_DISPLAY_NAME';
export const RECEIVED_USER_BLUEPRINTS_SUMMARIES      = 'RECEIVED_USER_BLUEPRINTS_SUMMARIES';
export const RECEIVED_USER_BLUEPRINTS_KEYS           = 'RECEIVED_USER_BLUEPRINTS_KEYS';

export const AUTH_STATE_CHANGED  = 'AUTH_STATE_CHANGED';
export const EDITED_DISPLAY_NAME = 'EDITED_DISPLAY_NAME';

export const SUBSCRIBE_TO_MY_FAVORITES       = 'SUBSCRIBE_TO_MY_FAVORITES';
export const RECEIVED_MY_FAVORITES_KEYS      = 'RECEIVED_MY_FAVORITES_KEYS';
export const RECEIVED_MY_FAVORITES_SUMMARIES = 'RECEIVED_MY_FAVORITES_SUMMARIES';

export const SUBSCRIBE_TO_MODERATORS  = 'SUBSCRIBE_TO_MODERATORS';
export const SUBSCRIBED_TO_MODERATORS = 'SUBSCRIBED_TO_MODERATORS';
export const RECEIVED_MODERATORS      = 'RECEIVED_MODERATORS';

export const FILTER_ON_TAGS  = 'FILTER_ON_TAGS';
export const FILTER_ON_TITLE = 'FILTER_ON_TITLE';

