const entitiesWithIcons = {
	'1x2-remnants'                                                  : true,
	'accumulator-explosion'                                         : true,
	'accumulator-recycling'                                         : true,
	'accumulator-remnants'                                          : true,
	'accumulator'                                                   : true,
	'acid-neutralisation'                                           : true,
	'acid-refined-concrete'                                         : true,
	'active-provider-chest-explosion'                               : true,
	'active-provider-chest-recycling'                               : true,
	'active-provider-chest-remnants'                                : true,
	'active-provider-chest'                                         : true,
	'advanced-asteroid-processing'                                  : true,
	'advanced-carbonic-asteroid-crushing'                           : true,
	'advanced-circuit-recycling'                                    : true,
	'advanced-circuit'                                              : true,
	'advanced-combinators'                                          : true,
	'advanced-material-processing-2'                                : true,
	'advanced-material-processing'                                  : true,
	'advanced-metallic-asteroid-crushing'                           : true,
	'advanced-oil-processing'                                       : true,
	'advanced-oxide-asteroid-crushing'                              : true,
	'advanced-thruster-fuel'                                        : true,
	'advanced-thruster-oxidizer'                                    : true,
	'agricultural-science-pack-recycling'                           : true,
	'agricultural-science-pack'                                     : true,
	'agricultural-tower-explosion'                                  : true,
	'agricultural-tower-recycling'                                  : true,
	'agricultural-tower-remnants'                                   : true,
	'agricultural-tower'                                            : true,
	'agriculture'                                                   : true,
	'alien-artifact-goo'                                            : true,
	'ammonia-rocket-fuel'                                           : true,
	'ammonia'                                                       : true,
	'ammoniacal-ocean-2'                                            : true,
	'ammoniacal-ocean'                                              : true,
	'ammoniacal-solution-separation'                                : true,
	'ammoniacal-solution'                                           : true,
	'aquilo-tiles-inner-explosion'                                  : true,
	'aquilo-tiles-outer-explosion'                                  : true,
	'aquilo'                                                        : true,
	'arithmetic-combinator-explosion'                               : true,
	'arithmetic-combinator-recycling'                               : true,
	'arithmetic-combinator-remnants'                                : true,
	'arithmetic-combinator'                                         : true,
	'artificial-jellynut-soil-recycling'                            : true,
	'artificial-jellynut-soil'                                      : true,
	'artificial-soil'                                               : true,
	'artificial-yumako-soil-recycling'                              : true,
	'artificial-yumako-soil'                                        : true,
	'artillery-cannon-muzzle-flash'                                 : true,
	'artillery-flare'                                               : true,
	'artillery-shell-damage-1'                                      : true,
	'artillery-shell-range-1'                                       : true,
	'artillery-shell-recycling'                                     : true,
	'artillery-shell-speed-1'                                       : true,
	'artillery-shell'                                               : true,
	'artillery-targeting-remote-recycling'                          : true,
	'artillery-targeting-remote'                                    : true,
	'artillery-turret-explosion'                                    : true,
	'artillery-turret-recycling'                                    : true,
	'artillery-turret-remnants'                                     : true,
	'artillery-turret'                                              : true,
	'artillery-wagon-cannon-recycling'                              : true,
	'artillery-wagon-cannon'                                        : true,
	'artillery-wagon-explosion'                                     : true,
	'artillery-wagon-recycling'                                     : true,
	'artillery-wagon-remnants'                                      : true,
	'artillery-wagon'                                               : true,
	'artillery'                                                     : true,
	'ashland-lichen-tree-flaming'                                   : true,
	'ashland-lichen-tree-stump'                                     : true,
	'ashland-lichen-tree'                                           : true,
	'assembling-machine-1-explosion'                                : true,
	'assembling-machine-1-recycling'                                : true,
	'assembling-machine-1-remnants'                                 : true,
	'assembling-machine-1'                                          : true,
	'assembling-machine-2-explosion'                                : true,
	'assembling-machine-2-recycling'                                : true,
	'assembling-machine-2-remnants'                                 : true,
	'assembling-machine-2'                                          : true,
	'assembling-machine-3-explosion'                                : true,
	'assembling-machine-3-recycling'                                : true,
	'assembling-machine-3-remnants'                                 : true,
	'assembling-machine-3'                                          : true,
	'asteroid-collector-recycling'                                  : true,
	'asteroid-collector'                                            : true,
	'asteroid-productivity'                                         : true,
	'asteroid-reprocessing'                                         : true,
	'atomic-bomb-recycling'                                         : true,
	'atomic-bomb'                                                   : true,
	'atomic-nuke-shockwave'                                         : true,
	'automated-rail-transportation'                                 : true,
	'automation-2'                                                  : true,
	'automation-3'                                                  : true,
	'automation-science-pack-recycling'                             : true,
	'automation-science-pack'                                       : true,
	'automation'                                                    : true,
	'automobilism'                                                  : true,
	'bacteria-cultivation'                                          : true,
	'barrel-empty-side-mask'                                        : true,
	'barrel-empty-top-mask'                                         : true,
	'barrel-empty'                                                  : true,
	'barrel-fill-side-mask'                                         : true,
	'barrel-fill-top-mask'                                          : true,
	'barrel-fill'                                                   : true,
	'barrel-hoop-top-mask'                                          : true,
	'barrel-recycling'                                              : true,
	'barrel-side-mask'                                              : true,
	'barrel'                                                        : true,
	'basic-oil-processing'                                          : true,
	'battery-equipment-recycling'                                   : true,
	'battery-equipment'                                             : true,
	'battery-mk2-equipment-recycling'                               : true,
	'battery-mk2-equipment'                                         : true,
	'battery-mk3-equipment-recycling'                               : true,
	'battery-mk3-equipment'                                         : true,
	'battery-recycling'                                             : true,
	'battery'                                                       : true,
	'beacon-explosion'                                              : true,
	'beacon-recycling'                                              : true,
	'beacon-remnants'                                               : true,
	'beacon'                                                        : true,
	'behemoth-biter-corpse'                                         : true,
	'behemoth-biter-die'                                            : true,
	'behemoth-biter'                                                : true,
	'behemoth-spitter-corpse'                                       : true,
	'behemoth-spitter-die'                                          : true,
	'behemoth-spitter'                                              : true,
	'behemoth-worm-corpse-burrowed'                                 : true,
	'behemoth-worm-corpse'                                          : true,
	'behemoth-worm-die'                                             : true,
	'behemoth-worm-turret'                                          : true,
	'belt-immunity-equipment-recycling'                             : true,
	'belt-immunity-equipment'                                       : true,
	'big-artillery-explosion'                                       : true,
	'big-biter-corpse'                                              : true,
	'big-biter-die'                                                 : true,
	'big-biter'                                                     : true,
	'big-carbonic-asteroid'                                         : true,
	'big-demolisher-corpse'                                         : true,
	'big-demolisher-fissure-damage-explosion'                       : true,
	'big-demolisher-fissure-explosion'                              : true,
	'big-demolisher-fissure-scorchmark'                             : true,
	'big-demolisher-fissure'                                        : true,
	'big-demolisher-trail-lower'                                    : true,
	'big-demolisher-trail-upper'                                    : true,
	'big-demolisher'                                                : true,
	'big-electric-pole-explosion'                                   : true,
	'big-electric-pole-recycling'                                   : true,
	'big-electric-pole-remnants'                                    : true,
	'big-electric-pole'                                             : true,
	'big-explosion'                                                 : true,
	'big-fulgora-rock'                                              : true,
	'big-metallic-asteroid'                                         : true,
	'big-mining-drill-explosion'                                    : true,
	'big-mining-drill-recycling'                                    : true,
	'big-mining-drill-remnants'                                     : true,
	'big-mining-drill'                                              : true,
	'big-oxide-asteroid'                                            : true,
	'big-promethium-asteroid'                                       : true,
	'big-remnants'                                                  : true,
	'big-rock'                                                      : true,
	'big-sand-rock'                                                 : true,
	'big-scorchmark-tintable'                                       : true,
	'big-scorchmark'                                                : true,
	'big-ship-wreck-1'                                              : true,
	'big-ship-wreck-2'                                              : true,
	'big-ship-wreck-3'                                              : true,
	'big-ship-wreck-grass'                                          : true,
	'big-spitter-corpse'                                            : true,
	'big-spitter-die'                                               : true,
	'big-spitter'                                                   : true,
	'big-stomper-corpse'                                            : true,
	'big-stomper-pentapod-die'                                      : true,
	'big-stomper-pentapod-leg-die'                                  : true,
	'big-stomper-pentapod-leg'                                      : true,
	'big-stomper-pentapod'                                          : true,
	'big-stomper-shell'                                             : true,
	'big-strafer-corpse'                                            : true,
	'big-strafer-pentapod-die'                                      : true,
	'big-strafer-pentapod-leg-die'                                  : true,
	'big-strafer-pentapod-leg'                                      : true,
	'big-strafer-pentapod'                                          : true,
	'big-volcanic-rock'                                             : true,
	'big-worm-corpse-burrowed'                                      : true,
	'big-worm-corpse'                                               : true,
	'big-worm-die'                                                  : true,
	'big-worm-turret'                                               : true,
	'big-worm'                                                      : true,
	'big-wriggler-die'                                              : true,
	'big-wriggler-pentapod-corpse'                                  : true,
	'big-wriggler-pentapod-premature'                               : true,
	'big-wriggler-pentapod'                                         : true,
	'biochamber-explosion'                                          : true,
	'biochamber-recycling'                                          : true,
	'biochamber-remnants'                                           : true,
	'biochamber'                                                    : true,
	'bioflux-processing'                                            : true,
	'bioflux-recycling'                                             : true,
	'bioflux'                                                       : true,
	'biolab-explosion'                                              : true,
	'biolab-recycling'                                              : true,
	'biolab-remnants'                                               : true,
	'biolab'                                                        : true,
	'biolubricant'                                                  : true,
	'bioplastic'                                                    : true,
	'biosulfur'                                                     : true,
	'biter-egg-handling'                                            : true,
	'biter-egg-recycling'                                           : true,
	'biter-egg'                                                     : true,
	'biter-spawner-corpse'                                          : true,
	'biter-spawner-die'                                             : true,
	'biter-spawner'                                                 : true,
	'black-refined-concrete'                                        : true,
	'blood-explosion-big'                                           : true,
	'blood-explosion-huge'                                          : true,
	'blood-explosion-small'                                         : true,
	'blood-fountain-big'                                            : true,
	'blood-fountain-hit-spray'                                      : true,
	'blood-fountain'                                                : true,
	'blue-chest'                                                    : true,
	'blue-refined-concrete'                                         : true,
	'blueprint-book-recycling'                                      : true,
	'blueprint-book'                                                : true,
	'blueprint-recycling'                                           : true,
	'blueprint'                                                     : true,
	'boiler-explosion'                                              : true,
	'boiler-recycling'                                              : true,
	'boiler-remnants'                                               : true,
	'boiler'                                                        : true,
	'boompuff'                                                      : true,
	'braking-force-1'                                               : true,
	'braking-force-2'                                               : true,
	'braking-force-3'                                               : true,
	'braking-force-4'                                               : true,
	'braking-force-5'                                               : true,
	'braking-force-6'                                               : true,
	'braking-force-7'                                               : true,
	'brash-ice'                                                     : true,
	'brown-asterisk'                                                : true,
	'brown-cane-cluster'                                            : true,
	'brown-cane-single'                                             : true,
	'brown-carpet-grass'                                            : true,
	'brown-coral-mini'                                              : true,
	'brown-fluff-dry'                                               : true,
	'brown-fluff'                                                   : true,
	'brown-hairy-grass'                                             : true,
	'brown-refined-concrete'                                        : true,
	'buffer-chest-explosion'                                        : true,
	'buffer-chest-recycling'                                        : true,
	'buffer-chest-remnants'                                         : true,
	'buffer-chest'                                                  : true,
	'bulk-inserter-explosion'                                       : true,
	'bulk-inserter-recycling'                                       : true,
	'bulk-inserter-remnants'                                        : true,
	'bulk-inserter'                                                 : true,
	'burner-generator-recycling'                                    : true,
	'burner-generator'                                              : true,
	'burner-inserter-explosion'                                     : true,
	'burner-inserter-recycling'                                     : true,
	'burner-inserter-remnants'                                      : true,
	'burner-inserter'                                               : true,
	'burner-mining-drill-explosion'                                 : true,
	'burner-mining-drill-recycling'                                 : true,
	'burner-mining-drill-remnants'                                  : true,
	'burner-mining-drill'                                           : true,
	'burnt-spoilage'                                                : true,
	'calcite-processing'                                            : true,
	'calcite-recycling'                                             : true,
	'calcite'                                                       : true,
	'cannon-shell-recycling'                                        : true,
	'cannon-shell'                                                  : true,
	'captive-biter-spawner-recycling'                               : true,
	'captive-biter-spawner'                                         : true,
	'captive-spawner-explosion-1'                                   : true,
	'captive-spawner-explosion-2'                                   : true,
	'captivity'                                                     : true,
	'capture-robot-explosion'                                       : true,
	'capture-robot-rocket-recycling'                                : true,
	'capture-robot-rocket'                                          : true,
	'capture-robot'                                                 : true,
	'car-explosion'                                                 : true,
	'car-recycling'                                                 : true,
	'car-remnants'                                                  : true,
	'car'                                                           : true,
	'carbon-fiber-recycling'                                        : true,
	'carbon-fiber'                                                  : true,
	'carbon-recycling'                                              : true,
	'carbon'                                                        : true,
	'carbonic-asteroid-chunk-recycling'                             : true,
	'carbonic-asteroid-chunk'                                       : true,
	'carbonic-asteroid-crushing'                                    : true,
	'carbonic-asteroid-explosion-1'                                 : true,
	'carbonic-asteroid-explosion-2'                                 : true,
	'carbonic-asteroid-explosion-3'                                 : true,
	'carbonic-asteroid-explosion-4'                                 : true,
	'carbonic-asteroid-explosion-5'                                 : true,
	'carbonic-asteroid-reprocessing'                                : true,
	'cargo-bay-recycling'                                           : true,
	'cargo-bay-remnants'                                            : true,
	'cargo-bay'                                                     : true,
	'cargo-landing-pad-recycling'                                   : true,
	'cargo-landing-pad-remnants'                                    : true,
	'cargo-landing-pad'                                             : true,
	'cargo-pod-container-explosion'                                 : true,
	'cargo-pod-container-remnants'                                  : true,
	'cargo-pod-container'                                           : true,
	'cargo-pod'                                                     : true,
	'cargo-wagon-explosion'                                         : true,
	'cargo-wagon-recycling'                                         : true,
	'cargo-wagon-remnants'                                          : true,
	'cargo-wagon'                                                   : true,
	'casting-copper-cable'                                          : true,
	'casting-copper'                                                : true,
	'casting-iron-gear-wheel'                                       : true,
	'casting-iron-stick'                                            : true,
	'casting-iron'                                                  : true,
	'casting-low-density-structure'                                 : true,
	'casting-pipe-to-ground'                                        : true,
	'casting-pipe'                                                  : true,
	'casting-steel'                                                 : true,
	'centrifuge-explosion'                                          : true,
	'centrifuge-recycling'                                          : true,
	'centrifuge-remnants'                                           : true,
	'centrifuge'                                                    : true,
	'character-corpse'                                              : true,
	'character'                                                     : true,
	'chemical-plant-explosion'                                      : true,
	'chemical-plant-recycling'                                      : true,
	'chemical-plant-remnants'                                       : true,
	'chemical-plant'                                                : true,
	'chemical-science-pack-recycling'                               : true,
	'chemical-science-pack'                                         : true,
	'circuit-network'                                               : true,
	'cliff-explosives-recycling'                                    : true,
	'cliff-explosives'                                              : true,
	'cliff-fulgora'                                                 : true,
	'cliff-gleba'                                                   : true,
	'cliff-icon'                                                    : true,
	'cliff-vulcanus'                                                : true,
	'cliff'                                                         : true,
	'cluster-grenade-recycling'                                     : true,
	'cluster-grenade'                                               : true,
	'cluster-nuke-explosion'                                        : true,
	'coal-dark-background'                                          : true,
	'coal-liquefaction'                                             : true,
	'coal-recycling'                                                : true,
	'coal-synthesis'                                                : true,
	'coal'                                                          : true,
	'coin-recycling'                                                : true,
	'coin'                                                          : true,
	'combat-shotgun-recycling'                                      : true,
	'combat-shotgun'                                                : true,
	'combat'                                                        : true,
	'computer'                                                      : true,
	'concrete-from-molten-iron'                                     : true,
	'concrete-recycling'                                            : true,
	'concrete'                                                      : true,
	'constant-combinator-explosion'                                 : true,
	'constant-combinator-recycling'                                 : true,
	'constant-combinator-remnants'                                  : true,
	'constant-combinator'                                           : true,
	'construction-robot-explosion'                                  : true,
	'construction-robot-recycling'                                  : true,
	'construction-robot-remnants'                                   : true,
	'construction-robot'                                            : true,
	'construction-robotics'                                         : true,
	'copper-bacteria-cultivation'                                   : true,
	'copper-bacteria-recycling'                                     : true,
	'copper-bacteria'                                               : true,
	'copper-cable-recycling'                                        : true,
	'copper-cable'                                                  : true,
	'copper-ore-recycling'                                          : true,
	'copper-ore'                                                    : true,
	'copper-plate-recycling'                                        : true,
	'copper-plate'                                                  : true,
	'copper-stromatolite'                                           : true,
	'copper-wire-recycling'                                         : true,
	'copper-wire'                                                   : true,
	'copy-paste-tool-recycling'                                     : true,
	'copy-paste-tool'                                               : true,
	'crash-site-chest-1'                                            : true,
	'crash-site-chest-2'                                            : true,
	'crash-site-spaceship-wreck-big-1'                              : true,
	'crash-site-spaceship-wreck-big-2'                              : true,
	'crash-site-spaceship-wreck-medium-1'                           : true,
	'crash-site-spaceship-wreck-medium-2'                           : true,
	'crash-site-spaceship-wreck-medium-3'                           : true,
	'crash-site-spaceship-wreck-small-1'                            : true,
	'crash-site-spaceship-wreck-small-2'                            : true,
	'crash-site-spaceship-wreck-small-3'                            : true,
	'crash-site-spaceship-wreck-small-4'                            : true,
	'crash-site-spaceship-wreck-small-5'                            : true,
	'crash-site-spaceship-wreck-small-6'                            : true,
	'crash-site-spaceship'                                          : true,
	'crater-cliff'                                                  : true,
	'creative-mod_active-electric-energy-interface-input-recycling' : true,
	'creative-mod_active-electric-energy-interface-input'           : true,
	'creative-mod_active-electric-energy-interface-output-recycling': true,
	'creative-mod_active-electric-energy-interface-output'          : true,
	'creative-mod_alien-attractor-large-recycling'                  : true,
	'creative-mod_alien-attractor-large'                            : true,
	'creative-mod_alien-attractor-medium-recycling'                 : true,
	'creative-mod_alien-attractor-medium'                           : true,
	'creative-mod_alien-attractor-small-recycling'                  : true,
	'creative-mod_alien-attractor-small'                            : true,
	'creative-mod_autofill-requester-chest-recycling'               : true,
	'creative-mod_autofill-requester-chest'                         : true,
	'creative-mod_configurable-super-boiler-recycling'              : true,
	'creative-mod_configurable-super-boiler'                        : true,
	'creative-mod_creative-cargo-wagon-recycling'                   : true,
	'creative-mod_creative-cargo-wagon'                             : true,
	'creative-mod_creative-chest-recycling'                         : true,
	'creative-mod_creative-chest'                                   : true,
	'creative-mod_creative-lab-recycling'                           : true,
	'creative-mod_creative-lab'                                     : true,
	'creative-mod_creative-provider-chest-recycling'                : true,
	'creative-mod_creative-provider-chest'                          : true,
	'creative-mod_creative-tools'                                   : true,
	'creative-mod_duplicating-cargo-wagon-recycling'                : true,
	'creative-mod_duplicating-cargo-wagon'                          : true,
	'creative-mod_duplicating-chest-recycling'                      : true,
	'creative-mod_duplicating-chest'                                : true,
	'creative-mod_duplicating-provider-chest-recycling'             : true,
	'creative-mod_duplicating-provider-chest'                       : true,
	'creative-mod_duplicator-recycling'                             : true,
	'creative-mod_duplicator'                                       : true,
	'creative-mod_enemy-object_behemoth-biter'                      : true,
	'creative-mod_enemy-object_behemoth-spitter'                    : true,
	'creative-mod_enemy-object_behemoth-worm-turret'                : true,
	'creative-mod_enemy-object_big-biter'                           : true,
	'creative-mod_enemy-object_big-spitter'                         : true,
	'creative-mod_enemy-object_big-worm-turret'                     : true,
	'creative-mod_enemy-object_big-wriggler-pentapod'               : true,
	'creative-mod_enemy-object_biter-spawner'                       : true,
	'creative-mod_enemy-object_gleba-spawner-small'                 : true,
	'creative-mod_enemy-object_gleba-spawner'                       : true,
	'creative-mod_enemy-object_medium-biter'                        : true,
	'creative-mod_enemy-object_medium-spitter'                      : true,
	'creative-mod_enemy-object_medium-worm-turret'                  : true,
	'creative-mod_enemy-object_medium-wriggler-pentapod'            : true,
	'creative-mod_enemy-object_small-biter'                         : true,
	'creative-mod_enemy-object_small-spitter'                       : true,
	'creative-mod_enemy-object_small-worm-turret'                   : true,
	'creative-mod_enemy-object_small-wriggler-pentapod'             : true,
	'creative-mod_enemy-object_spitter-spawner'                     : true,
	'creative-mod_energy-absorption-recycling'                      : true,
	'creative-mod_energy-absorption'                                : true,
	'creative-mod_energy-source-recycling'                          : true,
	'creative-mod_energy-source'                                    : true,
	'creative-mod_energy-void-recycling'                            : true,
	'creative-mod_energy-void'                                      : true,
	'creative-mod_fluid-source-recycling'                           : true,
	'creative-mod_fluid-source'                                     : true,
	'creative-mod_fluid-void-recycling'                             : true,
	'creative-mod_fluid-void'                                       : true,
	'creative-mod_free-fluid-ammonia'                               : true,
	'creative-mod_free-fluid-ammoniacal-solution'                   : true,
	'creative-mod_free-fluid-crude-oil'                             : true,
	'creative-mod_free-fluid-electrolyte'                           : true,
	'creative-mod_free-fluid-fluorine'                              : true,
	'creative-mod_free-fluid-fluoroketone-cold'                     : true,
	'creative-mod_free-fluid-fluoroketone-hot'                      : true,
	'creative-mod_free-fluid-fusion-plasma'                         : true,
	'creative-mod_free-fluid-heavy-oil'                             : true,
	'creative-mod_free-fluid-holmium-solution'                      : true,
	'creative-mod_free-fluid-lava'                                  : true,
	'creative-mod_free-fluid-light-oil'                             : true,
	'creative-mod_free-fluid-lithium-brine'                         : true,
	'creative-mod_free-fluid-lubricant'                             : true,
	'creative-mod_free-fluid-molten-copper'                         : true,
	'creative-mod_free-fluid-molten-iron'                           : true,
	'creative-mod_free-fluid-parameter-0'                           : true,
	'creative-mod_free-fluid-parameter-1'                           : true,
	'creative-mod_free-fluid-parameter-2'                           : true,
	'creative-mod_free-fluid-parameter-3'                           : true,
	'creative-mod_free-fluid-parameter-4'                           : true,
	'creative-mod_free-fluid-parameter-5'                           : true,
	'creative-mod_free-fluid-parameter-6'                           : true,
	'creative-mod_free-fluid-parameter-7'                           : true,
	'creative-mod_free-fluid-parameter-8'                           : true,
	'creative-mod_free-fluid-parameter-9'                           : true,
	'creative-mod_free-fluid-petroleum-gas'                         : true,
	'creative-mod_free-fluid-steam'                                 : true,
	'creative-mod_free-fluid-sulfuric-acid'                         : true,
	'creative-mod_free-fluid-thruster-fuel'                         : true,
	'creative-mod_free-fluid-thruster-oxidizer'                     : true,
	'creative-mod_free-fluid-water'                                 : true,
	'creative-mod_heat-source-recycling'                            : true,
	'creative-mod_heat-source'                                      : true,
	'creative-mod_heat-void-recycling'                              : true,
	'creative-mod_heat-void'                                        : true,
	'creative-mod_inf-provider-chest-recycling'                     : true,
	'creative-mod_inf-provider-chest'                               : true,
	'creative-mod_inf-requester-chest-recycling'                    : true,
	'creative-mod_inf-requester-chest'                              : true,
	'creative-mod_infinite-calcite'                                 : true,
	'creative-mod_infinite-coal'                                    : true,
	'creative-mod_infinite-copper-ore'                              : true,
	'creative-mod_infinite-iron-ore'                                : true,
	'creative-mod_infinite-lithium-brine'                           : true,
	'creative-mod_infinite-scrap'                                   : true,
	'creative-mod_infinite-stone'                                   : true,
	'creative-mod_infinite-tungsten-ore'                            : true,
	'creative-mod_infinite-uranium-ore'                             : true,
	'creative-mod_item-source-recycling'                            : true,
	'creative-mod_item-source'                                      : true,
	'creative-mod_item-void-recycling'                              : true,
	'creative-mod_item-void'                                        : true,
	'creative-mod_linked-belt-recycling'                            : true,
	'creative-mod_linked-belt'                                      : true,
	'creative-mod_linked-chest-recycling'                           : true,
	'creative-mod_linked-chest'                                     : true,
	'creative-mod_magic-wand-creator-recycling'                     : true,
	'creative-mod_magic-wand-creator'                               : true,
	'creative-mod_magic-wand-healer-recycling'                      : true,
	'creative-mod_magic-wand-healer'                                : true,
	'creative-mod_magic-wand-modifier-recycling'                    : true,
	'creative-mod_magic-wand-modifier'                              : true,
	'creative-mod_new-autofill-requester-chest'                     : true,
	'creative-mod_new-creative-chest'                               : true,
	'creative-mod_new-creative-provider-chest'                      : true,
	'creative-mod_new-fluid-void'                                   : true,
	'creative-mod_passive-electric-energy-interface-recycling'      : true,
	'creative-mod_passive-electric-energy-interface'                : true,
	'creative-mod_passive-energy-source-recycling'                  : true,
	'creative-mod_passive-energy-source'                            : true,
	'creative-mod_passive-energy-void-recycling'                    : true,
	'creative-mod_passive-energy-void'                              : true,
	'creative-mod_random-item-source-recycling'                     : true,
	'creative-mod_random-item-source'                               : true,
	'creative-mod_super-beacon-recycling'                           : true,
	'creative-mod_super-beacon'                                     : true,
	'creative-mod_super-boiler-recycling'                           : true,
	'creative-mod_super-boiler'                                     : true,
	'creative-mod_super-clean-module-recycling'                     : true,
	'creative-mod_super-clean-module'                               : true,
	'creative-mod_super-construction-robot-recycling'               : true,
	'creative-mod_super-construction-robot'                         : true,
	'creative-mod_super-consumption-module-recycling'               : true,
	'creative-mod_super-consumption-module'                         : true,
	'creative-mod_super-cooler-recycling'                           : true,
	'creative-mod_super-cooler'                                     : true,
	'creative-mod_super-effectivity-module-recycling'               : true,
	'creative-mod_super-effectivity-module'                         : true,
	'creative-mod_super-electric-pole-recycling'                    : true,
	'creative-mod_super-electric-pole'                              : true,
	'creative-mod_super-fusion-reactor-equipment-recycling'         : true,
	'creative-mod_super-fusion-reactor-equipment'                   : true,
	'creative-mod_super-loader-recycling'                           : true,
	'creative-mod_super-loader'                                     : true,
	'creative-mod_super-loader2-recycling'                          : true,
	'creative-mod_super-loader2'                                    : true,
	'creative-mod_super-logistic-robot-recycling'                   : true,
	'creative-mod_super-logistic-robot'                             : true,
	'creative-mod_super-personal-roboport-equipment-recycling'      : true,
	'creative-mod_super-personal-roboport-equipment'                : true,
	'creative-mod_super-pollution-module-recycling'                 : true,
	'creative-mod_super-pollution-module'                           : true,
	'creative-mod_super-productivity-module-recycling'              : true,
	'creative-mod_super-productivity-module'                        : true,
	'creative-mod_super-radar-2-recycling'                          : true,
	'creative-mod_super-radar-2'                                    : true,
	'creative-mod_super-radar-recycling'                            : true,
	'creative-mod_super-radar'                                      : true,
	'creative-mod_super-roboport-recycling'                         : true,
	'creative-mod_super-roboport'                                   : true,
	'creative-mod_super-slow-module-recycling'                      : true,
	'creative-mod_super-slow-module'                                : true,
	'creative-mod_super-speed-module-recycling'                     : true,
	'creative-mod_super-speed-module'                               : true,
	'creative-mod_super-substation-recycling'                       : true,
	'creative-mod_super-substation'                                 : true,
	'creative-mod_void-cargo-wagon-recycling'                       : true,
	'creative-mod_void-cargo-wagon'                                 : true,
	'creative-mod_void-chest-recycling'                             : true,
	'creative-mod_void-chest'                                       : true,
	'creative-mod_void-lab-recycling'                               : true,
	'creative-mod_void-lab'                                         : true,
	'creative-mod_void-requester-chest-recycling'                   : true,
	'creative-mod_void-requester-chest'                             : true,
	'creative-mod_void-storage-chest-recycling'                     : true,
	'creative-mod_void-storage-chest'                               : true,
	'creative-mod_void-technology'                                  : true,
	'creeper'                                                       : true,
	'crude-oil-barrel-recycling'                                    : true,
	'crude-oil-barrel'                                              : true,
	'crude-oil'                                                     : true,
	'crusher-recycling'                                             : true,
	'crusher'                                                       : true,
	'cryogenic-plant-explosion'                                     : true,
	'cryogenic-plant-recycling'                                     : true,
	'cryogenic-plant-remnants'                                      : true,
	'cryogenic-plant'                                               : true,
	'cryogenic-science-pack-recycling'                              : true,
	'cryogenic-science-pack'                                        : true,
	'curved-rail-a-remnants'                                        : true,
	'curved-rail-a'                                                 : true,
	'curved-rail-b-remnants'                                        : true,
	'curved-rail-b'                                                 : true,
	'curved-rail-remnants'                                          : true,
	'curved-rail'                                                   : true,
	'cut-paste-tool-recycling'                                      : true,
	'cut-paste-tool'                                                : true,
	'cuttlepop'                                                     : true,
	'cyan-refined-concrete'                                         : true,
	'dead-dry-hairy-tree'                                           : true,
	'dead-grey-trunk'                                               : true,
	'dead-tree-desert'                                              : true,
	'dead-tree'                                                     : true,
	'decider-combinator-explosion'                                  : true,
	'decider-combinator-recycling'                                  : true,
	'decider-combinator-remnants'                                   : true,
	'decider-combinator'                                            : true,
	'deconstruction-planner-recycling'                              : true,
	'deconstruction-planner'                                        : true,
	'deepwater-green'                                               : true,
	'deepwater'                                                     : true,
	'defender-capsule-recycling'                                    : true,
	'defender-capsule'                                              : true,
	'defender-remnants'                                             : true,
	'defender-robot-explosion'                                      : true,
	'defender'                                                      : true,
	'depleted-uranium-fuel-cell-recycling'                          : true,
	'depleted-uranium-fuel-cell'                                    : true,
	'destroyer-capsule-recycling'                                   : true,
	'destroyer-capsule'                                             : true,
	'destroyer-remnants'                                            : true,
	'destroyer-robot-explosion'                                     : true,
	'destroyer'                                                     : true,
	'diesel-locomotive'                                             : true,
	'dirt-1'                                                        : true,
	'dirt-2'                                                        : true,
	'dirt-3'                                                        : true,
	'dirt-4'                                                        : true,
	'dirt-5'                                                        : true,
	'dirt-6'                                                        : true,
	'dirt-7'                                                        : true,
	'dirt'                                                          : true,
	'discharge-defense-equipment-recycling'                         : true,
	'discharge-defense-equipment'                                   : true,
	'discharge-defense-remote-recycling'                            : true,
	'discharge-defense-remote'                                      : true,
	'display-panel-recycling'                                       : true,
	'display-panel'                                                 : true,
	'distractor-capsule-recycling'                                  : true,
	'distractor-capsule'                                            : true,
	'distractor-remnants'                                           : true,
	'distractor-robot-explosion'                                    : true,
	'distractor'                                                    : true,
	'down-arrow'                                                    : true,
	'down-left-arrow'                                               : true,
	'down-right-arrow'                                              : true,
	'dry-dirt'                                                      : true,
	'dry-hairy-tree'                                                : true,
	'dry-tree'                                                      : true,
	'dummy-elevated-curved-rail-a'                                  : true,
	'dummy-elevated-curved-rail-b'                                  : true,
	'dummy-elevated-half-diagonal-rail'                             : true,
	'dummy-elevated-straight-rail'                                  : true,
	'dummy-rail-ramp'                                               : true,
	'dummy-rail-support'                                            : true,
	'dummy-spider-unit'                                             : true,
	'dust-crests'                                                   : true,
	'dust-flat'                                                     : true,
	'dust-lumpy'                                                    : true,
	'dust-patchy'                                                   : true,
	'effect-transmission'                                           : true,
	'effectivity-module-2'                                          : true,
	'effectivity-module-3'                                          : true,
	'effectivity-module'                                            : true,
	'effects'                                                       : true,
	'efficiency-module-2-recycling'                                 : true,
	'efficiency-module-2'                                           : true,
	'efficiency-module-3-recycling'                                 : true,
	'efficiency-module-3'                                           : true,
	'efficiency-module-recycling'                                   : true,
	'efficiency-module'                                             : true,
	'electric-energy-accumulators'                                  : true,
	'electric-energy-distribution-1'                                : true,
	'electric-energy-distribution-2'                                : true,
	'electric-energy-interface-recycling'                           : true,
	'electric-energy-interface'                                     : true,
	'electric-engine-unit-recycling'                                : true,
	'electric-engine-unit'                                          : true,
	'electric-engine'                                               : true,
	'electric-furnace-explosion'                                    : true,
	'electric-furnace-recycling'                                    : true,
	'electric-furnace-remnants'                                     : true,
	'electric-furnace'                                              : true,
	'electric-mining-drill-explosion'                               : true,
	'electric-mining-drill-recycling'                               : true,
	'electric-mining-drill-remnants'                                : true,
	'electric-mining-drill'                                         : true,
	'electric-weapons-damage-1'                                     : true,
	'electric-weapons-damage-2'                                     : true,
	'electric-weapons-damage-3'                                     : true,
	'electric-weapons-damage-4'                                     : true,
	'electrolyte'                                                   : true,
	'electromagnetic-plant-explosion'                               : true,
	'electromagnetic-plant-recycling'                               : true,
	'electromagnetic-plant-remnants'                                : true,
	'electromagnetic-plant'                                         : true,
	'electromagnetic-science-pack-recycling'                        : true,
	'electromagnetic-science-pack'                                  : true,
	'electronic-circuit-recycling'                                  : true,
	'electronic-circuit'                                            : true,
	'electronics'                                                   : true,
	'elevated-curved-rail-a'                                        : true,
	'elevated-curved-rail-b'                                        : true,
	'elevated-half-diagonal-rail'                                   : true,
	'elevated-rail-explosion'                                       : true,
	'elevated-rail-remnants'                                        : true,
	'elevated-rail'                                                 : true,
	'elevated-straight-rail'                                        : true,
	'empty-barrel'                                                  : true,
	'empty-crude-oil-barrel'                                        : true,
	'empty-fluoroketone-cold-barrel'                                : true,
	'empty-fluoroketone-hot-barrel'                                 : true,
	'empty-heavy-oil-barrel'                                        : true,
	'empty-light-oil-barrel'                                        : true,
	'empty-lubricant-barrel'                                        : true,
	'empty-module-slot-recycling'                                   : true,
	'empty-module-slot'                                             : true,
	'empty-petroleum-gas-barrel'                                    : true,
	'empty-space'                                                   : true,
	'empty-sulfuric-acid-barrel'                                    : true,
	'empty-water-barrel'                                            : true,
	'enemies'                                                       : true,
	'enemy-damaged-explosion'                                       : true,
	'energy-shield-equipment-recycling'                             : true,
	'energy-shield-equipment'                                       : true,
	'energy-shield-mk2-equipment-recycling'                         : true,
	'energy-shield-mk2-equipment'                                   : true,
	'engine-unit-recycling'                                         : true,
	'engine-unit'                                                   : true,
	'engine'                                                        : true,
	'entity-ghost'                                                  : true,
	'entity-unknown'                                                : true,
	'environment'                                                   : true,
	'epic-quality'                                                  : true,
	'epic'                                                          : true,
	'exoskeleton-equipment-recycling'                               : true,
	'exoskeleton-equipment'                                         : true,
	'explosion-gunshot-small'                                       : true,
	'explosion-gunshot'                                             : true,
	'explosion-hit'                                                 : true,
	'explosion'                                                     : true,
	'explosive-cannon-shell-recycling'                              : true,
	'explosive-cannon-shell'                                        : true,
	'explosive-rocket-recycling'                                    : true,
	'explosive-rocket'                                              : true,
	'explosive-rocketry'                                            : true,
	'explosive-uranium-cannon-shell-recycling'                      : true,
	'explosive-uranium-cannon-shell'                                : true,
	'explosives-recycling'                                          : true,
	'explosives'                                                    : true,
	'express-loader-recycling'                                      : true,
	'express-loader'                                                : true,
	'express-splitter-explosion'                                    : true,
	'express-splitter-recycling'                                    : true,
	'express-splitter-remnants'                                     : true,
	'express-splitter'                                              : true,
	'express-transport-belt-explosion'                              : true,
	'express-transport-belt-recycling'                              : true,
	'express-transport-belt-remnants'                               : true,
	'express-transport-belt'                                        : true,
	'express-underground-belt-explosion'                            : true,
	'express-underground-belt-recycling'                            : true,
	'express-underground-belt-remnants'                             : true,
	'express-underground-belt'                                      : true,
	'factorio-logo-11tiles'                                         : true,
	'factorio-logo-16tiles'                                         : true,
	'factorio-logo-22tiles'                                         : true,
	'fast-inserter-explosion'                                       : true,
	'fast-inserter-recycling'                                       : true,
	'fast-inserter-remnants'                                        : true,
	'fast-inserter'                                                 : true,
	'fast-loader-recycling'                                         : true,
	'fast-loader'                                                   : true,
	'fast-splitter-explosion'                                       : true,
	'fast-splitter-recycling'                                       : true,
	'fast-splitter-remnants'                                        : true,
	'fast-splitter'                                                 : true,
	'fast-transport-belt-explosion'                                 : true,
	'fast-transport-belt-recycling'                                 : true,
	'fast-transport-belt-remnants'                                  : true,
	'fast-transport-belt'                                           : true,
	'fast-underground-belt-explosion'                               : true,
	'fast-underground-belt-recycling'                               : true,
	'fast-underground-belt-remnants'                                : true,
	'fast-underground-belt'                                         : true,
	'filter-inserter'                                               : true,
	'firearm-magazine-recycling'                                    : true,
	'firearm-magazine'                                              : true,
	'fish-breeding'                                                 : true,
	'fish'                                                          : true,
	'fission-reactor-equipment-recycling'                           : true,
	'fission-reactor-equipment'                                     : true,
	'flamethrower-ammo-recycling'                                   : true,
	'flamethrower-ammo'                                             : true,
	'flamethrower-recycling'                                        : true,
	'flamethrower-turret-explosion'                                 : true,
	'flamethrower-turret-recycling'                                 : true,
	'flamethrower-turret-remnants'                                  : true,
	'flamethrower-turret'                                           : true,
	'flamethrower'                                                  : true,
	'flammables'                                                    : true,
	'fluid-handling'                                                : true,
	'fluid-unknown'                                                 : true,
	'fluid-wagon-explosion'                                         : true,
	'fluid-wagon-recycling'                                         : true,
	'fluid-wagon-remnants'                                          : true,
	'fluid-wagon'                                                   : true,
	'fluids'                                                        : true,
	'fluorine-vent'                                                 : true,
	'fluorine'                                                      : true,
	'fluoroketone-cold-barrel-recycling'                            : true,
	'fluoroketone-cold-barrel'                                      : true,
	'fluoroketone-cold'                                             : true,
	'fluoroketone-cooling'                                          : true,
	'fluoroketone-hot-barrel-recycling'                             : true,
	'fluoroketone-hot-barrel'                                       : true,
	'fluoroketone-hot'                                              : true,
	'fluoroketone'                                                  : true,
	'flying-robot-damaged-explosion'                                : true,
	'flying-robot-frame-recycling'                                  : true,
	'flying-robot-frame'                                            : true,
	'follower-robot-count-1'                                        : true,
	'follower-robot-count-2'                                        : true,
	'follower-robot-count-3'                                        : true,
	'follower-robot-count-4'                                        : true,
	'follower-robot-count-5'                                        : true,
	'foundation-recycling'                                          : true,
	'foundation'                                                    : true,
	'foundry-explosion'                                             : true,
	'foundry-recycling'                                             : true,
	'foundry-remnants'                                              : true,
	'foundry'                                                       : true,
	'frozen-concrete'                                               : true,
	'frozen-hazard-concrete-left'                                   : true,
	'frozen-hazard-concrete-right'                                  : true,
	'frozen-refined-concrete'                                       : true,
	'frozen-refined-hazard-concrete-left'                           : true,
	'frozen-refined-hazard-concrete-right'                          : true,
	'fulgora'                                                       : true,
	'fulgoran-conduit'                                              : true,
	'fulgoran-dunes'                                                : true,
	'fulgoran-dust'                                                 : true,
	'fulgoran-machinery'                                            : true,
	'fulgoran-paving'                                               : true,
	'fulgoran-rock'                                                 : true,
	'fulgoran-ruin-attractor'                                       : true,
	'fulgoran-ruin-big'                                             : true,
	'fulgoran-ruin-colossal'                                        : true,
	'fulgoran-ruin-huge'                                            : true,
	'fulgoran-ruin-medium'                                          : true,
	'fulgoran-ruin-small'                                           : true,
	'fulgoran-ruin-stonehenge'                                      : true,
	'fulgoran-ruin-vault'                                           : true,
	'fulgoran-sand'                                                 : true,
	'fulgoran-walls'                                                : true,
	'fulgurite-small'                                               : true,
	'fulgurite'                                                     : true,
	'funneltrunk'                                                   : true,
	'fusion-generator-explosion'                                    : true,
	'fusion-generator-recycling'                                    : true,
	'fusion-generator-remnants'                                     : true,
	'fusion-generator'                                              : true,
	'fusion-plasma'                                                 : true,
	'fusion-power-cell-recycling'                                   : true,
	'fusion-power-cell'                                             : true,
	'fusion-reactor-equipment-recycling'                            : true,
	'fusion-reactor-equipment'                                      : true,
	'fusion-reactor-explosion'                                      : true,
	'fusion-reactor-recycling'                                      : true,
	'fusion-reactor-remnants'                                       : true,
	'fusion-reactor'                                                : true,
	'garballo-mini-dry'                                             : true,
	'garballo'                                                      : true,
	'gate-explosion'                                                : true,
	'gate-recycling'                                                : true,
	'gate-remnants'                                                 : true,
	'gate'                                                          : true,
	'gleba-blood-explosion-big'                                     : true,
	'gleba-blood-explosion-huge'                                    : true,
	'gleba-blood-explosion-small'                                   : true,
	'gleba-blood-fountain-big'                                      : true,
	'gleba-blood-fountain-hit-spray'                                : true,
	'gleba-blood-fountain'                                          : true,
	'gleba-deep-lake'                                               : true,
	'gleba-enemy-damaged-explosion'                                 : true,
	'gleba-spawner-corpse-small'                                    : true,
	'gleba-spawner-corpse'                                          : true,
	'gleba-spawner-small'                                           : true,
	'gleba-spawner'                                                 : true,
	'gleba'                                                         : true,
	'grass-1'                                                       : true,
	'grass-2'                                                       : true,
	'grass-3'                                                       : true,
	'grass-4'                                                       : true,
	'grass'                                                         : true,
	'green-asterisk'                                                : true,
	'green-bush-mini'                                               : true,
	'green-carpet-grass'                                            : true,
	'green-coral-mini'                                              : true,
	'green-coral'                                                   : true,
	'green-hairy-grass'                                             : true,
	'green-pita-mini'                                               : true,
	'green-pita'                                                    : true,
	'green-refined-concrete'                                        : true,
	'green-small-grass'                                             : true,
	'green-wire-recycling'                                          : true,
	'green-wire'                                                    : true,
	'grenade-explosion'                                             : true,
	'grenade-recycling'                                             : true,
	'grenade'                                                       : true,
	'ground-explosion'                                              : true,
	'gun-turret-explosion'                                          : true,
	'gun-turret-recycling'                                          : true,
	'gun-turret-remnants'                                           : true,
	'gun-turret'                                                    : true,
	'hairyclubnub'                                                  : true,
	'half-diagonal-rail-remnants'                                   : true,
	'half-diagonal-rail'                                            : true,
	'hazard-concrete-left'                                          : true,
	'hazard-concrete-recycling'                                     : true,
	'hazard-concrete-right'                                         : true,
	'hazard-concrete'                                               : true,
	'health'                                                        : true,
	'heat-boiler'                                                   : true,
	'heat-exchanger-explosion'                                      : true,
	'heat-exchanger-recycling'                                      : true,
	'heat-exchanger-remnants'                                       : true,
	'heat-exchanger'                                                : true,
	'heat-interface-recycling'                                      : true,
	'heat-interface'                                                : true,
	'heat-pipe-explosion'                                           : true,
	'heat-pipe-recycling'                                           : true,
	'heat-pipe-remnants'                                            : true,
	'heat-pipe'                                                     : true,
	'heating-tower-explosion'                                       : true,
	'heating-tower-recycling'                                       : true,
	'heating-tower-remnants'                                        : true,
	'heating-tower'                                                 : true,
	'heavy-armor-recycling'                                         : true,
	'heavy-armor'                                                   : true,
	'heavy-oil-barrel-recycling'                                    : true,
	'heavy-oil-barrel'                                              : true,
	'heavy-oil-cracking'                                            : true,
	'heavy-oil'                                                     : true,
	'hidden-electric-energy-interface'                              : true,
	'high-tech-science-pack'                                        : true,
	'highland-dark-rock-2'                                          : true,
	'highland-dark-rock'                                            : true,
	'highland-yellow-rock'                                          : true,
	'holmium-ore-recycling'                                         : true,
	'holmium-ore'                                                   : true,
	'holmium-plate-recycling'                                       : true,
	'holmium-plate'                                                 : true,
	'holmium-processing'                                            : true,
	'holmium-solution'                                              : true,
	'huge-carbonic-asteroid'                                        : true,
	'huge-metallic-asteroid'                                        : true,
	'huge-oxide-asteroid'                                           : true,
	'huge-promethium-asteroid'                                      : true,
	'huge-rock'                                                     : true,
	'huge-scorchmark-tintable'                                      : true,
	'huge-scorchmark'                                               : true,
	'huge-volcanic-rock'                                            : true,
	'ice-melting'                                                   : true,
	'ice-platform-recycling'                                        : true,
	'ice-platform'                                                  : true,
	'ice-recycling'                                                 : true,
	'ice-rough'                                                     : true,
	'ice-smooth'                                                    : true,
	'ice'                                                           : true,
	'infinity-chest-recycling'                                      : true,
	'infinity-chest'                                                : true,
	'infinity-pipe-recycling'                                       : true,
	'infinity-pipe'                                                 : true,
	'inserter-capacity-bonus-1'                                     : true,
	'inserter-capacity-bonus-2'                                     : true,
	'inserter-capacity-bonus-3'                                     : true,
	'inserter-capacity-bonus-4'                                     : true,
	'inserter-capacity-bonus-5'                                     : true,
	'inserter-capacity-bonus-6'                                     : true,
	'inserter-capacity-bonus-7'                                     : true,
	'inserter-explosion'                                            : true,
	'inserter-recycling'                                            : true,
	'inserter-remnants'                                             : true,
	'inserter'                                                      : true,
	'intermediate-products'                                         : true,
	'iron-axe'                                                      : true,
	'iron-bacteria-cultivation'                                     : true,
	'iron-bacteria-recycling'                                       : true,
	'iron-bacteria'                                                 : true,
	'iron-chest-explosion'                                          : true,
	'iron-chest-recycling'                                          : true,
	'iron-chest-remnants'                                           : true,
	'iron-chest'                                                    : true,
	'iron-gear-wheel-recycling'                                     : true,
	'iron-gear-wheel'                                               : true,
	'iron-ore-recycling'                                            : true,
	'iron-ore'                                                      : true,
	'iron-plate-recycling'                                          : true,
	'iron-plate'                                                    : true,
	'iron-stick-recycling'                                          : true,
	'iron-stick'                                                    : true,
	'iron-stromatolite'                                             : true,
	'item-on-ground'                                                : true,
	'item-request-proxy'                                            : true,
	'item-unknown-recycling'                                        : true,
	'item-unknown'                                                  : true,
	'jelly-recycling'                                               : true,
	'jelly'                                                         : true,
	'jellynut-processing'                                           : true,
	'jellynut-recycling'                                            : true,
	'jellynut-seed-recycling'                                       : true,
	'jellynut-seed'                                                 : true,
	'jellynut'                                                      : true,
	'jellystem'                                                     : true,
	'kovarex-enrichment-process'                                    : true,
	'lab-dark-1'                                                    : true,
	'lab-dark-2'                                                    : true,
	'lab-explosion'                                                 : true,
	'lab-recycling'                                                 : true,
	'lab-remnants'                                                  : true,
	'lab-white'                                                     : true,
	'lab'                                                           : true,
	'lamp-explosion'                                                : true,
	'lamp-remnants'                                                 : true,
	'lamp'                                                          : true,
	'land-mine-explosion'                                           : true,
	'land-mine-recycling'                                           : true,
	'land-mine-remnants'                                            : true,
	'land-mine'                                                     : true,
	'landfill-recycling'                                            : true,
	'landfill'                                                      : true,
	'lane-splitter-recycling'                                       : true,
	'lane-splitter'                                                 : true,
	'laser-bubble'                                                  : true,
	'laser-shooting-speed-1'                                        : true,
	'laser-shooting-speed-2'                                        : true,
	'laser-shooting-speed-3'                                        : true,
	'laser-shooting-speed-4'                                        : true,
	'laser-shooting-speed-5'                                        : true,
	'laser-shooting-speed-6'                                        : true,
	'laser-shooting-speed-7'                                        : true,
	'laser-turret-explosion'                                        : true,
	'laser-turret-recycling'                                        : true,
	'laser-turret-remnants'                                         : true,
	'laser-turret'                                                  : true,
	'laser-weapons-damage-1'                                        : true,
	'laser-weapons-damage-2'                                        : true,
	'laser-weapons-damage-3'                                        : true,
	'laser-weapons-damage-4'                                        : true,
	'laser-weapons-damage-5'                                        : true,
	'laser-weapons-damage-6'                                        : true,
	'laser-weapons-damage-7'                                        : true,
	'laser'                                                         : true,
	'lava-hot'                                                      : true,
	'lava'                                                          : true,
	'left-arrow'                                                    : true,
	'legacy-curved-rail-remnants'                                   : true,
	'legacy-curved-rail'                                            : true,
	'legacy-straight-rail-remnants'                                 : true,
	'legacy-straight-rail'                                          : true,
	'legendary-quality'                                             : true,
	'legendary'                                                     : true,
	'lickmaw'                                                       : true,
	'light-armor-recycling'                                         : true,
	'light-armor'                                                   : true,
	'light-oil-barrel-recycling'                                    : true,
	'light-oil-barrel'                                              : true,
	'light-oil-cracking'                                            : true,
	'light-oil'                                                     : true,
	'lightning-collector-recycling'                                 : true,
	'lightning-collector-remnants'                                  : true,
	'lightning-collector'                                           : true,
	'lightning-rod-recycling'                                       : true,
	'lightning-rod-remnants'                                        : true,
	'lightning-rod'                                                 : true,
	'lightning'                                                     : true,
	'linked-belt-recycling'                                         : true,
	'linked-belt'                                                   : true,
	'linked-chest-recycling'                                        : true,
	'linked-chest'                                                  : true,
	'lithium-brine'                                                 : true,
	'lithium-iceberg-big'                                           : true,
	'lithium-iceberg-huge'                                          : true,
	'lithium-plate-recycling'                                       : true,
	'lithium-plate'                                                 : true,
	'lithium-processing'                                            : true,
	'lithium-recycling'                                             : true,
	'lithium'                                                       : true,
	'loader-1x1'                                                    : true,
	'loader-recycling'                                              : true,
	'loader'                                                        : true,
	'locomotive-explosion'                                          : true,
	'locomotive-recycling'                                          : true,
	'locomotive-remnants'                                           : true,
	'locomotive'                                                    : true,
	'logistic-chest-active-provider'                                : true,
	'logistic-chest-buffer'                                         : true,
	'logistic-chest-passive-provider'                               : true,
	'logistic-chest-requester'                                      : true,
	'logistic-chest-storage'                                        : true,
	'logistic-robot-explosion'                                      : true,
	'logistic-robot-recycling'                                      : true,
	'logistic-robot-remnants'                                       : true,
	'logistic-robot'                                                : true,
	'logistic-robotics'                                             : true,
	'logistic-science-pack-recycling'                               : true,
	'logistic-science-pack'                                         : true,
	'logistic-system'                                               : true,
	'logistics-2'                                                   : true,
	'logistics-3'                                                   : true,
	'logistics'                                                     : true,
	'long-handed-inserter-explosion'                                : true,
	'long-handed-inserter-recycling'                                : true,
	'long-handed-inserter-remnants'                                 : true,
	'long-handed-inserter'                                          : true,
	'low-density-structure-productivity'                            : true,
	'low-density-structure-recycling'                               : true,
	'low-density-structure'                                         : true,
	'lowland-brown-blubber'                                         : true,
	'lowland-cream-cauliflower-2'                                   : true,
	'lowland-cream-cauliflower'                                     : true,
	'lowland-cream-red'                                             : true,
	'lowland-dead-skin-2'                                           : true,
	'lowland-dead-skin'                                             : true,
	'lowland-olive-blubber-2'                                       : true,
	'lowland-olive-blubber-3'                                       : true,
	'lowland-olive-blubber'                                         : true,
	'lowland-pale-green'                                            : true,
	'lowland-red-infection'                                         : true,
	'lowland-red-vein-2'                                            : true,
	'lowland-red-vein-3'                                            : true,
	'lowland-red-vein-4'                                            : true,
	'lowland-red-vein-dead'                                         : true,
	'lowland-red-vein'                                              : true,
	'lubricant-barrel-recycling'                                    : true,
	'lubricant-barrel'                                              : true,
	'lubricant'                                                     : true,
	'market'                                                        : true,
	'massive-explosion'                                             : true,
	'mech-armor-recycling'                                          : true,
	'mech-armor'                                                    : true,
	'medium-biter-corpse'                                           : true,
	'medium-biter-die'                                              : true,
	'medium-biter'                                                  : true,
	'medium-carbonic-asteroid'                                      : true,
	'medium-demolisher-corpse'                                      : true,
	'medium-demolisher-fissure-damage-explosion'                    : true,
	'medium-demolisher-fissure-explosion'                           : true,
	'medium-demolisher-fissure-scorchmark'                          : true,
	'medium-demolisher-fissure'                                     : true,
	'medium-demolisher-trail-lower'                                 : true,
	'medium-demolisher-trail-upper'                                 : true,
	'medium-demolisher'                                             : true,
	'medium-electric-pole-explosion'                                : true,
	'medium-electric-pole-recycling'                                : true,
	'medium-electric-pole-remnants'                                 : true,
	'medium-electric-pole'                                          : true,
	'medium-explosion'                                              : true,
	'medium-metallic-asteroid'                                      : true,
	'medium-oxide-asteroid'                                         : true,
	'medium-promethium-asteroid'                                    : true,
	'medium-remnants'                                               : true,
	'medium-scorchmark-tintable'                                    : true,
	'medium-scorchmark'                                             : true,
	'medium-ship-wreck'                                             : true,
	'medium-small-remnants'                                         : true,
	'medium-spitter-corpse'                                         : true,
	'medium-spitter-die'                                            : true,
	'medium-spitter'                                                : true,
	'medium-stomper-corpse'                                         : true,
	'medium-stomper-pentapod-die'                                   : true,
	'medium-stomper-pentapod-leg-die'                               : true,
	'medium-stomper-pentapod-leg'                                   : true,
	'medium-stomper-pentapod'                                       : true,
	'medium-stomper-shell'                                          : true,
	'medium-strafer-corpse'                                         : true,
	'medium-strafer-pentapod-die'                                   : true,
	'medium-strafer-pentapod-leg-die'                               : true,
	'medium-strafer-pentapod-leg'                                   : true,
	'medium-strafer-pentapod'                                       : true,
	'medium-worm-corpse-burrowed'                                   : true,
	'medium-worm-corpse'                                            : true,
	'medium-worm-die'                                               : true,
	'medium-worm-turret'                                            : true,
	'medium-worm'                                                   : true,
	'medium-wriggler-die'                                           : true,
	'medium-wriggler-pentapod-corpse'                               : true,
	'medium-wriggler-pentapod-premature'                            : true,
	'medium-wriggler-pentapod'                                      : true,
	'metallic-asteroid-chunk-recycling'                             : true,
	'metallic-asteroid-chunk'                                       : true,
	'metallic-asteroid-crushing'                                    : true,
	'metallic-asteroid-explosion-1'                                 : true,
	'metallic-asteroid-explosion-2'                                 : true,
	'metallic-asteroid-explosion-3'                                 : true,
	'metallic-asteroid-explosion-4'                                 : true,
	'metallic-asteroid-explosion-5'                                 : true,
	'metallic-asteroid-reprocessing'                                : true,
	'metallurgic-science-pack-recycling'                            : true,
	'metallurgic-science-pack'                                      : true,
	'midland-cracked-lichen-dark'                                   : true,
	'midland-cracked-lichen-dull'                                   : true,
	'midland-cracked-lichen'                                        : true,
	'midland-turquoise-bark-2'                                      : true,
	'midland-turquoise-bark'                                        : true,
	'midland-yellow-crust-2'                                        : true,
	'midland-yellow-crust-3'                                        : true,
	'midland-yellow-crust-4'                                        : true,
	'midland-yellow-crust'                                          : true,
	'military-2'                                                    : true,
	'military-3'                                                    : true,
	'military-4'                                                    : true,
	'military-science-pack-recycling'                               : true,
	'military-science-pack'                                         : true,
	'military'                                                      : true,
	'mining-productivity-1'                                         : true,
	'mining-productivity-2'                                         : true,
	'mining-productivity-3'                                         : true,
	'modular-armor-recycling'                                       : true,
	'modular-armor'                                                 : true,
	'modules'                                                       : true,
	'molten-copper-from-lava'                                       : true,
	'molten-copper'                                                 : true,
	'molten-iron-from-lava'                                         : true,
	'molten-iron'                                                   : true,
	'natural-jellynut-soil'                                         : true,
	'natural-yumako-soil'                                           : true,
	'nauvis'                                                        : true,
	'night-vision-equipment-recycling'                              : true,
	'night-vision-equipment'                                        : true,
	'normal'                                                        : true,
	'nuclear-fuel-recycling'                                        : true,
	'nuclear-fuel-reprocessing'                                     : true,
	'nuclear-fuel'                                                  : true,
	'nuclear-ground'                                                : true,
	'nuclear-power'                                                 : true,
	'nuclear-reactor-explosion'                                     : true,
	'nuclear-reactor-recycling'                                     : true,
	'nuclear-reactor-remnants'                                      : true,
	'nuclear-reactor'                                               : true,
	'nuclear-smouldering-smoke-source'                              : true,
	'nuke-explosion'                                                : true,
	'nutrients-from-bioflux'                                        : true,
	'nutrients-from-biter-egg'                                      : true,
	'nutrients-from-fish'                                           : true,
	'nutrients-from-spoilage'                                       : true,
	'nutrients-from-yumako-mash'                                    : true,
	'nutrients-recycling'                                           : true,
	'nutrients'                                                     : true,
	'offshore-pump-explosion'                                       : true,
	'offshore-pump-recycling'                                       : true,
	'offshore-pump-remnants'                                        : true,
	'offshore-pump'                                                 : true,
	'oil-gathering'                                                 : true,
	'oil-ocean-deep'                                                : true,
	'oil-ocean-shallow'                                             : true,
	'oil-processing'                                                : true,
	'oil-refinery-explosion'                                        : true,
	'oil-refinery-recycling'                                        : true,
	'oil-refinery-remnants'                                         : true,
	'oil-refinery'                                                  : true,
	'orange-coral-mini'                                             : true,
	'orange-refined-concrete'                                       : true,
	'other'                                                         : true,
	'out-of-map'                                                    : true,
	'overgrowth-jellynut-soil-recycling'                            : true,
	'overgrowth-jellynut-soil'                                      : true,
	'overgrowth-soil'                                               : true,
	'overgrowth-yumako-soil-recycling'                              : true,
	'overgrowth-yumako-soil'                                        : true,
	'oxide-asteroid-chunk-recycling'                                : true,
	'oxide-asteroid-chunk'                                          : true,
	'oxide-asteroid-crushing'                                       : true,
	'oxide-asteroid-explosion-1'                                    : true,
	'oxide-asteroid-explosion-2'                                    : true,
	'oxide-asteroid-explosion-3'                                    : true,
	'oxide-asteroid-explosion-4'                                    : true,
	'oxide-asteroid-explosion-5'                                    : true,
	'oxide-asteroid-reprocessing'                                   : true,
	'parameter-0-recycling'                                         : true,
	'parameter-0'                                                   : true,
	'parameter-1-recycling'                                         : true,
	'parameter-1'                                                   : true,
	'parameter-2-recycling'                                         : true,
	'parameter-2'                                                   : true,
	'parameter-3-recycling'                                         : true,
	'parameter-3'                                                   : true,
	'parameter-4-recycling'                                         : true,
	'parameter-4'                                                   : true,
	'parameter-5-recycling'                                         : true,
	'parameter-5'                                                   : true,
	'parameter-6-recycling'                                         : true,
	'parameter-6'                                                   : true,
	'parameter-7-recycling'                                         : true,
	'parameter-7'                                                   : true,
	'parameter-8-recycling'                                         : true,
	'parameter-8'                                                   : true,
	'parameter-9-recycling'                                         : true,
	'parameter-9'                                                   : true,
	'passive-provider-chest-explosion'                              : true,
	'passive-provider-chest-recycling'                              : true,
	'passive-provider-chest-remnants'                               : true,
	'passive-provider-chest'                                        : true,
	'pentapod-egg-recycling'                                        : true,
	'pentapod-egg'                                                  : true,
	'personal-laser-defense-equipment-recycling'                    : true,
	'personal-laser-defense-equipment'                              : true,
	'personal-roboport-equipment-recycling'                         : true,
	'personal-roboport-equipment'                                   : true,
	'personal-roboport-mk2-equipment-recycling'                     : true,
	'personal-roboport-mk2-equipment'                               : true,
	'petroleum-gas-barrel-recycling'                                : true,
	'petroleum-gas-barrel'                                          : true,
	'petroleum-gas'                                                 : true,
	'physical-projectile-damage-1'                                  : true,
	'physical-projectile-damage-2'                                  : true,
	'physical-projectile-damage-3'                                  : true,
	'physical-projectile-damage-4'                                  : true,
	'physical-projectile-damage-5'                                  : true,
	'physical-projectile-damage-6'                                  : true,
	'physical-projectile-damage-7'                                  : true,
	'piercing-rounds-magazine-recycling'                            : true,
	'piercing-rounds-magazine'                                      : true,
	'piercing-shotgun-shell-recycling'                              : true,
	'piercing-shotgun-shell'                                        : true,
	'pink-refined-concrete'                                         : true,
	'pipe-explosion'                                                : true,
	'pipe-recycling'                                                : true,
	'pipe-remnants'                                                 : true,
	'pipe-to-ground-explosion'                                      : true,
	'pipe-to-ground-recycling'                                      : true,
	'pipe-to-ground-remnants'                                       : true,
	'pipe-to-ground'                                                : true,
	'pipe'                                                          : true,
	'pistol-recycling'                                              : true,
	'pistol'                                                        : true,
	'pit-rock'                                                      : true,
	'planet-discovery-aquilo'                                       : true,
	'planet-discovery-fulgora'                                      : true,
	'planet-discovery-gleba'                                        : true,
	'planet-discovery-vulcanus'                                     : true,
	'plastic-bar-productivity'                                      : true,
	'plastic-bar-recycling'                                         : true,
	'plastic-bar'                                                   : true,
	'plastics'                                                      : true,
	'player-port'                                                   : true,
	'player'                                                        : true,
	'poison-capsule-recycling'                                      : true,
	'poison-capsule'                                                : true,
	'power-armor-mk2-recycling'                                     : true,
	'power-armor-mk2'                                               : true,
	'power-armor-recycling'                                         : true,
	'power-armor'                                                   : true,
	'power-switch-explosion'                                        : true,
	'power-switch-recycling'                                        : true,
	'power-switch-remnants'                                         : true,
	'power-switch'                                                  : true,
	'processing-unit-productivity'                                  : true,
	'processing-unit-recycling'                                     : true,
	'processing-unit'                                               : true,
	'production-science-pack-recycling'                             : true,
	'production-science-pack'                                       : true,
	'production'                                                    : true,
	'productivity-module-2-recycling'                               : true,
	'productivity-module-2'                                         : true,
	'productivity-module-3-recycling'                               : true,
	'productivity-module-3'                                         : true,
	'productivity-module-recycling'                                 : true,
	'productivity-module'                                           : true,
	'programmable-speaker-explosion'                                : true,
	'programmable-speaker-recycling'                                : true,
	'programmable-speaker-remnants'                                 : true,
	'programmable-speaker'                                          : true,
	'promethium-asteroid-chunk-recycling'                           : true,
	'promethium-asteroid-chunk'                                     : true,
	'promethium-asteroid-explosion-1'                               : true,
	'promethium-asteroid-explosion-2'                               : true,
	'promethium-asteroid-explosion-3'                               : true,
	'promethium-asteroid-explosion-4'                               : true,
	'promethium-asteroid-explosion-5'                               : true,
	'promethium-science-pack-recycling'                             : true,
	'promethium-science-pack'                                       : true,
	'pump-explosion'                                                : true,
	'pump-recycling'                                                : true,
	'pump-remnants'                                                 : true,
	'pump'                                                          : true,
	'pumpjack-explosion'                                            : true,
	'pumpjack-recycling'                                            : true,
	'pumpjack-remnants'                                             : true,
	'pumpjack'                                                      : true,
	'purple-refined-concrete'                                       : true,
	'quality-module-2-recycling'                                    : true,
	'quality-module-2'                                              : true,
	'quality-module-3-recycling'                                    : true,
	'quality-module-3'                                              : true,
	'quality-module-recycling'                                      : true,
	'quality-module'                                                : true,
	'quality-unknown'                                               : true,
	'quantum-processor-recycling'                                   : true,
	'quantum-processor'                                             : true,
	'radar-explosion'                                               : true,
	'radar-recycling'                                               : true,
	'radar-remnants'                                                : true,
	'radar'                                                         : true,
	'rail-chain-signal-explosion'                                   : true,
	'rail-chain-signal-recycling'                                   : true,
	'rail-chain-signal-remnants'                                    : true,
	'rail-chain-signal'                                             : true,
	'rail-ending-remnants'                                          : true,
	'rail-explosion'                                                : true,
	'rail-ramp-explosion-higher'                                    : true,
	'rail-ramp-explosion-lower'                                     : true,
	'rail-ramp-explosion-middle'                                    : true,
	'rail-ramp-recycling'                                           : true,
	'rail-ramp-remnants'                                            : true,
	'rail-ramp'                                                     : true,
	'rail-recycling'                                                : true,
	'rail-signal-explosion'                                         : true,
	'rail-signal-recycling'                                         : true,
	'rail-signal-remnants'                                          : true,
	'rail-signal'                                                   : true,
	'rail-support-explosion'                                        : true,
	'rail-support-foundations'                                      : true,
	'rail-support-recycling'                                        : true,
	'rail-support-remnants'                                         : true,
	'rail-support'                                                  : true,
	'rail'                                                          : true,
	'railgun-ammo-recycling'                                        : true,
	'railgun-ammo'                                                  : true,
	'railgun-damage-1'                                              : true,
	'railgun-recycling'                                             : true,
	'railgun-shooting-speed-1'                                      : true,
	'railgun-turret-recycling'                                      : true,
	'railgun-turret-remnants'                                       : true,
	'railgun-turret'                                                : true,
	'railgun'                                                       : true,
	'railway'                                                       : true,
	'rare'                                                          : true,
	'raw-fish-recycling'                                            : true,
	'raw-fish'                                                      : true,
	'raw-wood'                                                      : true,
	'recipe-unknown'                                                : true,
	'recycler-recycling'                                            : true,
	'recycler'                                                      : true,
	'recycling'                                                     : true,
	'red-asterisk'                                                  : true,
	'red-chest'                                                     : true,
	'red-desert-0'                                                  : true,
	'red-desert-1'                                                  : true,
	'red-desert-2'                                                  : true,
	'red-desert-3'                                                  : true,
	'red-desert-rock-big-icon'                                      : true,
	'red-desert-rock-huge-icon'                                     : true,
	'red-desert-rock-medium-icon'                                   : true,
	'red-refined-concrete'                                          : true,
	'red-wire-recycling'                                            : true,
	'red-wire'                                                      : true,
	'refined-concrete-recycling'                                    : true,
	'refined-concrete'                                              : true,
	'refined-flammables-1'                                          : true,
	'refined-flammables-2'                                          : true,
	'refined-flammables-3'                                          : true,
	'refined-flammables-4'                                          : true,
	'refined-flammables-5'                                          : true,
	'refined-flammables-6'                                          : true,
	'refined-flammables-7'                                          : true,
	'refined-hazard-concrete-left'                                  : true,
	'refined-hazard-concrete-recycling'                             : true,
	'refined-hazard-concrete-right'                                 : true,
	'refined-hazard-concrete'                                       : true,
	'remnants'                                                      : true,
	'repair-pack-recycling'                                         : true,
	'repair-pack'                                                   : true,
	'requester-chest-explosion'                                     : true,
	'requester-chest-recycling'                                     : true,
	'requester-chest-remnants'                                      : true,
	'requester-chest'                                               : true,
	'research-productivity'                                         : true,
	'research-speed-1'                                              : true,
	'research-speed-2'                                              : true,
	'research-speed-3'                                              : true,
	'research-speed-4'                                              : true,
	'research-speed-5'                                              : true,
	'research-speed-6'                                              : true,
	'right-arrow'                                                   : true,
	'roboport-explosion'                                            : true,
	'roboport-recycling'                                            : true,
	'roboport-remnants'                                             : true,
	'roboport'                                                      : true,
	'robotics'                                                      : true,
	'rock-big-icon'                                                 : true,
	'rock-damaged-explosion'                                        : true,
	'rock-huge-icon'                                                : true,
	'rocket-control-unit'                                           : true,
	'rocket-fuel-from-jelly'                                        : true,
	'rocket-fuel-productivity'                                      : true,
	'rocket-fuel-recycling'                                         : true,
	'rocket-fuel'                                                   : true,
	'rocket-launcher-recycling'                                     : true,
	'rocket-launcher'                                               : true,
	'rocket-part-productivity'                                      : true,
	'rocket-part-recycling'                                         : true,
	'rocket-part'                                                   : true,
	'rocket-recycling'                                              : true,
	'rocket-silo-explosion'                                         : true,
	'rocket-silo-recycling'                                         : true,
	'rocket-silo-remnants'                                          : true,
	'rocket-silo'                                                   : true,
	'rocket-structure'                                              : true,
	'rocket-turret-recycling'                                       : true,
	'rocket-turret-remnants'                                        : true,
	'rocket-turret'                                                 : true,
	'rocket'                                                        : true,
	'rocketry'                                                      : true,
	'root-a'                                                        : true,
	'root-b'                                                        : true,
	'root-tree'                                                     : true,
	'sand-1'                                                        : true,
	'sand-2'                                                        : true,
	'sand-3'                                                        : true,
	'satellite'                                                     : true,
	'science-pack-1'                                                : true,
	'science-pack-2'                                                : true,
	'science-pack-3'                                                : true,
	'science-recycling'                                             : true,
	'science'                                                       : true,
	'scrap-recycling-productivity'                                  : true,
	'scrap-recycling'                                               : true,
	'scrap'                                                         : true,
	'selection-tool-recycling'                                      : true,
	'selection-tool'                                                : true,
	'selector-combinator-explosion'                                 : true,
	'selector-combinator-recycling'                                 : true,
	'selector-combinator-remnants'                                  : true,
	'selector-combinator'                                           : true,
	'shape-circle'                                                  : true,
	'shape-corner'                                                  : true,
	'shape-cross'                                                   : true,
	'shape-curve'                                                   : true,
	'shape-diagonal-cross'                                          : true,
	'shape-diagonal'                                                : true,
	'shape-horizontal'                                              : true,
	'shape-t'                                                       : true,
	'shape-vertical'                                                : true,
	'shape_square'                                                  : true,
	'shattered-planet'                                              : true,
	'shotgun-recycling'                                             : true,
	'shotgun-shell-recycling'                                       : true,
	'shotgun-shell'                                                 : true,
	'shotgun'                                                       : true,
	'signal-0'                                                      : true,
	'signal-1'                                                      : true,
	'signal-2'                                                      : true,
	'signal-3'                                                      : true,
	'signal-4'                                                      : true,
	'signal-5'                                                      : true,
	'signal-6'                                                      : true,
	'signal-7'                                                      : true,
	'signal-8'                                                      : true,
	'signal-9'                                                      : true,
	'signal-A'                                                      : true,
	'signal-B'                                                      : true,
	'signal-C'                                                      : true,
	'signal-D'                                                      : true,
	'signal-E'                                                      : true,
	'signal-F'                                                      : true,
	'signal-G'                                                      : true,
	'signal-H'                                                      : true,
	'signal-I'                                                      : true,
	'signal-J'                                                      : true,
	'signal-K'                                                      : true,
	'signal-L'                                                      : true,
	'signal-M'                                                      : true,
	'signal-N'                                                      : true,
	'signal-O'                                                      : true,
	'signal-P'                                                      : true,
	'signal-Q'                                                      : true,
	'signal-R'                                                      : true,
	'signal-S'                                                      : true,
	'signal-T'                                                      : true,
	'signal-U'                                                      : true,
	'signal-V'                                                      : true,
	'signal-W'                                                      : true,
	'signal-X'                                                      : true,
	'signal-Y'                                                      : true,
	'signal-Z'                                                      : true,
	'signal-any-quality'                                            : true,
	'signal-anything'                                               : true,
	'signal-black'                                                  : true,
	'signal-blue'                                                   : true,
	'signal-check'                                                  : true,
	'signal-cyan'                                                   : true,
	'signal-deny'                                                   : true,
	'signal-dot'                                                    : true,
	'signal-each'                                                   : true,
	'signal-everything'                                             : true,
	'signal-fluid-parameter'                                        : true,
	'signal-fuel-parameter'                                         : true,
	'signal-ghost'                                                  : true,
	'signal-green'                                                  : true,
	'signal-grey'                                                   : true,
	'signal-heart'                                                  : true,
	'signal-info'                                                   : true,
	'signal-item-parameter'                                         : true,
	'signal-pink'                                                   : true,
	'signal-red'                                                    : true,
	'signal-signal-parameter'                                       : true,
	'signal-skull'                                                  : true,
	'signal-stack-size'                                             : true,
	'signal-unknown'                                                : true,
	'signal-white'                                                  : true,
	'signal-yellow'                                                 : true,
	'signal_0'                                                      : true,
	'signal_1'                                                      : true,
	'signal_2'                                                      : true,
	'signal_3'                                                      : true,
	'signal_4'                                                      : true,
	'signal_5'                                                      : true,
	'signal_6'                                                      : true,
	'signal_7'                                                      : true,
	'signal_8'                                                      : true,
	'signal_9'                                                      : true,
	'signal_A'                                                      : true,
	'signal_B'                                                      : true,
	'signal_C'                                                      : true,
	'signal_D'                                                      : true,
	'signal_E'                                                      : true,
	'signal_F'                                                      : true,
	'signal_G'                                                      : true,
	'signal_H'                                                      : true,
	'signal_I'                                                      : true,
	'signal_J'                                                      : true,
	'signal_K'                                                      : true,
	'signal_L'                                                      : true,
	'signal_M'                                                      : true,
	'signal_N'                                                      : true,
	'signal_O'                                                      : true,
	'signal_P'                                                      : true,
	'signal_Q'                                                      : true,
	'signal_R'                                                      : true,
	'signal_S'                                                      : true,
	'signal_T'                                                      : true,
	'signal_U'                                                      : true,
	'signal_V'                                                      : true,
	'signal_W'                                                      : true,
	'signal_X'                                                      : true,
	'signal_Y'                                                      : true,
	'signal_Z'                                                      : true,
	'signal_anything'                                               : true,
	'signal_black'                                                  : true,
	'signal_blue'                                                   : true,
	'signal_cyan'                                                   : true,
	'signal_each'                                                   : true,
	'signal_everything'                                             : true,
	'signal_green'                                                  : true,
	'signal_grey'                                                   : true,
	'signal_pink'                                                   : true,
	'signal_red'                                                    : true,
	'signal_white'                                                  : true,
	'signal_yellow'                                                 : true,
	'signals'                                                       : true,
	'simple-coal-liquefaction'                                      : true,
	'simple-entity-with-force-recycling'                            : true,
	'simple-entity-with-force'                                      : true,
	'simple-entity-with-owner-recycling'                            : true,
	'simple-entity-with-owner'                                      : true,
	'slipstack'                                                     : true,
	'slowdown-capsule-explosion'                                    : true,
	'slowdown-capsule-recycling'                                    : true,
	'slowdown-capsule'                                              : true,
	'small-biter-corpse'                                            : true,
	'small-biter-die'                                               : true,
	'small-biter'                                                   : true,
	'small-carbonic-asteroid'                                       : true,
	'small-demolisher-corpse'                                       : true,
	'small-demolisher-fissure-damage-explosion'                     : true,
	'small-demolisher-fissure-explosion'                            : true,
	'small-demolisher-fissure-scorchmark'                           : true,
	'small-demolisher-fissure'                                      : true,
	'small-demolisher-trail-lower'                                  : true,
	'small-demolisher-trail-upper'                                  : true,
	'small-demolisher'                                              : true,
	'small-electric-pole-explosion'                                 : true,
	'small-electric-pole-recycling'                                 : true,
	'small-electric-pole-remnants'                                  : true,
	'small-electric-pole'                                           : true,
	'small-lamp-recycling'                                          : true,
	'small-lamp'                                                    : true,
	'small-metallic-asteroid'                                       : true,
	'small-oxide-asteroid'                                          : true,
	'small-plane'                                                   : true,
	'small-promethium-asteroid'                                     : true,
	'small-remnants'                                                : true,
	'small-scorchmark-tintable'                                     : true,
	'small-scorchmark'                                              : true,
	'small-ship-wreck-grass'                                        : true,
	'small-ship-wreck'                                              : true,
	'small-spitter-corpse'                                          : true,
	'small-spitter-die'                                             : true,
	'small-spitter'                                                 : true,
	'small-stomper-corpse'                                          : true,
	'small-stomper-pentapod-die'                                    : true,
	'small-stomper-pentapod-leg-die'                                : true,
	'small-stomper-pentapod-leg'                                    : true,
	'small-stomper-pentapod'                                        : true,
	'small-stomper-shell'                                           : true,
	'small-stone-rock'                                              : true,
	'small-strafer-corpse'                                          : true,
	'small-strafer-pentapod-die'                                    : true,
	'small-strafer-pentapod-leg-die'                                : true,
	'small-strafer-pentapod-leg'                                    : true,
	'small-strafer-pentapod'                                        : true,
	'small-worm-corpse-burrowed'                                    : true,
	'small-worm-corpse'                                             : true,
	'small-worm-die'                                                : true,
	'small-worm-turret'                                             : true,
	'small-worm'                                                    : true,
	'small-wriggler-die'                                            : true,
	'small-wriggler-pentapod-corpse'                                : true,
	'small-wriggler-pentapod-premature'                             : true,
	'small-wriggler-pentapod'                                       : true,
	'snow-crests'                                                   : true,
	'snow-flat'                                                     : true,
	'snow-lumpy'                                                    : true,
	'snow-patchy'                                                   : true,
	'solar-energy'                                                  : true,
	'solar-panel-equipment-recycling'                               : true,
	'solar-panel-equipment'                                         : true,
	'solar-panel-explosion'                                         : true,
	'solar-panel-recycling'                                         : true,
	'solar-panel-remnants'                                          : true,
	'solar-panel'                                                   : true,
	'solar-system-edge'                                             : true,
	'solid-fuel-from-ammonia'                                       : true,
	'solid-fuel-from-heavy-oil'                                     : true,
	'solid-fuel-from-light-oil'                                     : true,
	'solid-fuel-from-petroleum-gas'                                 : true,
	'solid-fuel-recycling'                                          : true,
	'solid-fuel'                                                    : true,
	'space-location-unknown'                                        : true,
	'space-module-wreck'                                            : true,
	'space-platform-foundation-explosion'                           : true,
	'space-platform-foundation-recycling'                           : true,
	'space-platform-foundation'                                     : true,
	'space-platform-hub-recycling'                                  : true,
	'space-platform-hub'                                            : true,
	'space-platform-starter-pack-recycling'                         : true,
	'space-platform-starter-pack'                                   : true,
	'space-platform-thruster'                                       : true,
	'space-platform'                                                : true,
	'space-science-pack-recycling'                                  : true,
	'space-science-pack'                                            : true,
	'space'                                                         : true,
	'spark-explosion-higher'                                        : true,
	'spark-explosion'                                               : true,
	'speed-module-2-recycling'                                      : true,
	'speed-module-2'                                                : true,
	'speed-module-3-recycling'                                      : true,
	'speed-module-3'                                                : true,
	'speed-module-recycling'                                        : true,
	'speed-module'                                                  : true,
	'spidertron-explosion'                                          : true,
	'spidertron-leg-1'                                              : true,
	'spidertron-leg-2'                                              : true,
	'spidertron-leg-3'                                              : true,
	'spidertron-leg-4'                                              : true,
	'spidertron-leg-5'                                              : true,
	'spidertron-leg-6'                                              : true,
	'spidertron-leg-7'                                              : true,
	'spidertron-leg-8'                                              : true,
	'spidertron-recycling'                                          : true,
	'spidertron-remnants'                                           : true,
	'spidertron-remote-recycling'                                   : true,
	'spidertron-remote'                                             : true,
	'spidertron-rocket-launcher-1-recycling'                        : true,
	'spidertron-rocket-launcher-1'                                  : true,
	'spidertron-rocket-launcher-2-recycling'                        : true,
	'spidertron-rocket-launcher-2'                                  : true,
	'spidertron-rocket-launcher-3-recycling'                        : true,
	'spidertron-rocket-launcher-3'                                  : true,
	'spidertron-rocket-launcher-4-recycling'                        : true,
	'spidertron-rocket-launcher-4'                                  : true,
	'spidertron'                                                    : true,
	'spitter-spawner-corpse'                                        : true,
	'spitter-spawner-die'                                           : true,
	'spitter-spawner'                                               : true,
	'splitter-explosion'                                            : true,
	'splitter-recycling'                                            : true,
	'splitter-remnants'                                             : true,
	'splitter'                                                      : true,
	'spoilage-recycling'                                            : true,
	'spoilage'                                                      : true,
	'stack-filter-inserter'                                         : true,
	'stack-inserter-explosion'                                      : true,
	'stack-inserter-recycling'                                      : true,
	'stack-inserter-remnants'                                       : true,
	'stack-inserter'                                                : true,
	'steam-condensation'                                            : true,
	'steam-engine-explosion'                                        : true,
	'steam-engine-recycling'                                        : true,
	'steam-engine-remnants'                                         : true,
	'steam-engine'                                                  : true,
	'steam-power'                                                   : true,
	'steam-turbine-explosion'                                       : true,
	'steam-turbine-recycling'                                       : true,
	'steam-turbine-remnants'                                        : true,
	'steam-turbine'                                                 : true,
	'steam'                                                         : true,
	'steel-axe'                                                     : true,
	'steel-chest-explosion'                                         : true,
	'steel-chest-recycling'                                         : true,
	'steel-chest-remnants'                                          : true,
	'steel-chest'                                                   : true,
	'steel-furnace-explosion'                                       : true,
	'steel-furnace-recycling'                                       : true,
	'steel-furnace-remnants'                                        : true,
	'steel-furnace'                                                 : true,
	'steel-plate-productivity'                                      : true,
	'steel-plate-recycling'                                         : true,
	'steel-plate'                                                   : true,
	'steel-processing'                                              : true,
	'stingfrond'                                                    : true,
	'stone-brick-recycling'                                         : true,
	'stone-brick'                                                   : true,
	'stone-furnace-explosion'                                       : true,
	'stone-furnace-recycling'                                       : true,
	'stone-furnace-remnants'                                        : true,
	'stone-furnace'                                                 : true,
	'stone-path'                                                    : true,
	'stone-recycling'                                               : true,
	'stone-rock'                                                    : true,
	'stone-wall-recycling'                                          : true,
	'stone-wall'                                                    : true,
	'stone'                                                         : true,
	'storage-chest-explosion'                                       : true,
	'storage-chest-recycling'                                       : true,
	'storage-chest-remnants'                                        : true,
	'storage-chest'                                                 : true,
	'storage-tank-explosion'                                        : true,
	'storage-tank-recycling'                                        : true,
	'storage-tank-remnants'                                         : true,
	'storage-tank'                                                  : true,
	'straight-rail-remnants'                                        : true,
	'straight-rail'                                                 : true,
	'stronger-explosives-1'                                         : true,
	'stronger-explosives-2'                                         : true,
	'stronger-explosives-3'                                         : true,
	'stronger-explosives-4'                                         : true,
	'stronger-explosives-5'                                         : true,
	'stronger-explosives-6'                                         : true,
	'stronger-explosives-7'                                         : true,
	'submachine-gun-recycling'                                      : true,
	'submachine-gun'                                                : true,
	'substation-explosion'                                          : true,
	'substation-recycling'                                          : true,
	'substation-remnants'                                           : true,
	'substation'                                                    : true,
	'sulfur-processing'                                             : true,
	'sulfur-recycling'                                              : true,
	'sulfur'                                                        : true,
	'sulfuric-acid-barrel-recycling'                                : true,
	'sulfuric-acid-barrel'                                          : true,
	'sulfuric-acid-geyser'                                          : true,
	'sulfuric-acid'                                                 : true,
	'sunnycomb'                                                     : true,
	'supercapacitor-recycling'                                      : true,
	'supercapacitor'                                                : true,
	'superconductor-recycling'                                      : true,
	'superconductor'                                                : true,
	'tank-cannon-recycling'                                         : true,
	'tank-cannon'                                                   : true,
	'tank-explosion'                                                : true,
	'tank-flamethrower-recycling'                                   : true,
	'tank-flamethrower'                                             : true,
	'tank-machine-gun-recycling'                                    : true,
	'tank-machine-gun'                                              : true,
	'tank-recycling'                                                : true,
	'tank-remnants'                                                 : true,
	'tank'                                                          : true,
	'teflilly'                                                      : true,
	'tesla-ammo-recycling'                                          : true,
	'tesla-ammo'                                                    : true,
	'tesla-turret-recycling'                                        : true,
	'tesla-turret-remnants'                                         : true,
	'tesla-turret'                                                  : true,
	'tesla-weapons'                                                 : true,
	'teslagun-recycling'                                            : true,
	'teslagun'                                                      : true,
	'thruster-fuel'                                                 : true,
	'thruster-oxidizer'                                             : true,
	'thruster-recycling'                                            : true,
	'thruster'                                                      : true,
	'tile-ghost'                                                    : true,
	'tile-proxy'                                                    : true,
	'tile-unknown'                                                  : true,
	'tiles'                                                         : true,
	'toolbelt-equipment-recycling'                                  : true,
	'toolbelt-equipment'                                            : true,
	'toolbelt'                                                      : true,
	'train-stop-explosion'                                          : true,
	'train-stop-recycling'                                          : true,
	'train-stop-remnants'                                           : true,
	'train-stop'                                                    : true,
	'transport-belt-capacity-1'                                     : true,
	'transport-belt-capacity-2'                                     : true,
	'transport-belt-explosion'                                      : true,
	'transport-belt-recycling'                                      : true,
	'transport-belt-remnants'                                       : true,
	'transport-belt'                                                : true,
	'tree-01-stump'                                                 : true,
	'tree-01'                                                       : true,
	'tree-02-red'                                                   : true,
	'tree-02-stump'                                                 : true,
	'tree-02'                                                       : true,
	'tree-03-stump'                                                 : true,
	'tree-03'                                                       : true,
	'tree-04-stump'                                                 : true,
	'tree-04'                                                       : true,
	'tree-05-stump'                                                 : true,
	'tree-05'                                                       : true,
	'tree-06-brown'                                                 : true,
	'tree-06-stump'                                                 : true,
	'tree-06'                                                       : true,
	'tree-07-stump'                                                 : true,
	'tree-07'                                                       : true,
	'tree-08-brown'                                                 : true,
	'tree-08-red'                                                   : true,
	'tree-08-stump'                                                 : true,
	'tree-08'                                                       : true,
	'tree-09-brown'                                                 : true,
	'tree-09-red'                                                   : true,
	'tree-09-stump'                                                 : true,
	'tree-09'                                                       : true,
	'tree-dying-proxy'                                              : true,
	'tree-plant'                                                    : true,
	'tree-proxy'                                                    : true,
	'tree-seed-recycling'                                           : true,
	'tree-seed'                                                     : true,
	'tree-seeding'                                                  : true,
	'tungsten-carbide-recycling'                                    : true,
	'tungsten-carbide'                                              : true,
	'tungsten-ore-recycling'                                        : true,
	'tungsten-ore'                                                  : true,
	'tungsten-plate-recycling'                                      : true,
	'tungsten-plate'                                                : true,
	'tungsten-steel'                                                : true,
	'turbo-loader-recycling'                                        : true,
	'turbo-loader'                                                  : true,
	'turbo-splitter-explosion'                                      : true,
	'turbo-splitter-recycling'                                      : true,
	'turbo-splitter-remnants'                                       : true,
	'turbo-splitter'                                                : true,
	'turbo-transport-belt-explosion'                                : true,
	'turbo-transport-belt-recycling'                                : true,
	'turbo-transport-belt-remnants'                                 : true,
	'turbo-transport-belt'                                          : true,
	'turbo-underground-belt-explosion'                              : true,
	'turbo-underground-belt-recycling'                              : true,
	'turbo-underground-belt-remnants'                               : true,
	'turbo-underground-belt'                                        : true,
	'tutorial-grid'                                                 : true,
	'uncommon'                                                      : true,
	'underground-belt-explosion'                                    : true,
	'underground-belt-recycling'                                    : true,
	'underground-belt-remnants'                                     : true,
	'underground-belt'                                              : true,
	'up-arrow'                                                      : true,
	'up-left-arrow'                                                 : true,
	'up-right-arrow'                                                : true,
	'upgrade-planner-recycling'                                     : true,
	'upgrade-planner'                                               : true,
	'uranium-235-recycling'                                         : true,
	'uranium-235'                                                   : true,
	'uranium-238-recycling'                                         : true,
	'uranium-238'                                                   : true,
	'uranium-ammo'                                                  : true,
	'uranium-cannon-explosion'                                      : true,
	'uranium-cannon-shell-explosion'                                : true,
	'uranium-cannon-shell-recycling'                                : true,
	'uranium-cannon-shell'                                          : true,
	'uranium-fuel-cell-recycling'                                   : true,
	'uranium-fuel-cell'                                             : true,
	'uranium-mining'                                                : true,
	'uranium-ore-recycling'                                         : true,
	'uranium-ore'                                                   : true,
	'uranium-processing'                                            : true,
	'uranium-rounds-magazine-recycling'                             : true,
	'uranium-rounds-magazine'                                       : true,
	'used-up-uranium-fuel-cell'                                     : true,
	'utility-science-pack-recycling'                                : true,
	'utility-science-pack'                                          : true,
	'vehicle-machine-gun-recycling'                                 : true,
	'vehicle-machine-gun'                                           : true,
	'volcanic-ash-cracks'                                           : true,
	'volcanic-ash-dark'                                             : true,
	'volcanic-ash-flats'                                            : true,
	'volcanic-ash-light'                                            : true,
	'volcanic-ash-soil'                                             : true,
	'volcanic-cracks-hot'                                           : true,
	'volcanic-cracks-warm'                                          : true,
	'volcanic-cracks'                                               : true,
	'volcanic-folds-flat'                                           : true,
	'volcanic-folds-warm'                                           : true,
	'volcanic-folds'                                                : true,
	'volcanic-jagged-ground'                                        : true,
	'volcanic-pumice-stones'                                        : true,
	'volcanic-smooth-stone-warm'                                    : true,
	'volcanic-smooth-stone'                                         : true,
	'volcanic-soil-dark'                                            : true,
	'volcanic-soil-light'                                           : true,
	'vulcanus-chimney-cold'                                         : true,
	'vulcanus-chimney-faded'                                        : true,
	'vulcanus-chimney-short'                                        : true,
	'vulcanus-chimney-truncated'                                    : true,
	'vulcanus-chimney'                                              : true,
	'vulcanus-cliff-collapse'                                       : true,
	'vulcanus'                                                      : true,
	'wall-damaged-explosion'                                        : true,
	'wall-explosion'                                                : true,
	'wall-remnants'                                                 : true,
	'water-barrel-recycling'                                        : true,
	'water-barrel'                                                  : true,
	'water-cane'                                                    : true,
	'water-green'                                                   : true,
	'water-mud'                                                     : true,
	'water-shallow'                                                 : true,
	'water-splash'                                                  : true,
	'water-wube'                                                    : true,
	'water'                                                         : true,
	'weapon-shooting-speed-1'                                       : true,
	'weapon-shooting-speed-2'                                       : true,
	'weapon-shooting-speed-3'                                       : true,
	'weapon-shooting-speed-4'                                       : true,
	'weapon-shooting-speed-5'                                       : true,
	'weapon-shooting-speed-6'                                       : true,
	'wetland-blue-slime'                                            : true,
	'wetland-dead-skin'                                             : true,
	'wetland-green-slime'                                           : true,
	'wetland-jellynut'                                              : true,
	'wetland-light-dead-skin'                                       : true,
	'wetland-light-green-slime'                                     : true,
	'wetland-pink-tentacle'                                         : true,
	'wetland-red-tentacle'                                          : true,
	'wetland-yumako'                                                : true,
	'wood-processing'                                               : true,
	'wood-recycling'                                                : true,
	'wood'                                                          : true,
	'wooden-chest-explosion'                                        : true,
	'wooden-chest-recycling'                                        : true,
	'wooden-chest-remnants'                                         : true,
	'wooden-chest'                                                  : true,
	'worker-robots-speed-1'                                         : true,
	'worker-robots-speed-2'                                         : true,
	'worker-robots-speed-3'                                         : true,
	'worker-robots-speed-4'                                         : true,
	'worker-robots-speed-5'                                         : true,
	'worker-robots-speed-6'                                         : true,
	'worker-robots-speed-7'                                         : true,
	'worker-robots-storage-1'                                       : true,
	'worker-robots-storage-2'                                       : true,
	'worker-robots-storage-3'                                       : true,
	'wube-logo-space-platform'                                      : true,
	'yellow-refined-concrete'                                       : true,
	'yumako-mash-recycling'                                         : true,
	'yumako-mash'                                                   : true,
	'yumako-processing'                                             : true,
	'yumako-recycling'                                              : true,
	'yumako-seed-recycling'                                         : true,
	'yumako-seed'                                                   : true,
	'yumako-tree'                                                   : true,
	'yumako'                                                        : true,
};

export default entitiesWithIcons;
